function Error() {
    return (
      <div className="Error main-page">
          <h1>
          404
          </h1>
      </div>
    );
  }
  
  export default Error;