import '../scss/OverView.scss';


function Overview() {
    return (
      <div className="overview main-page">
        <h1>
          Snowblossom
        </h1>
        <h2>
          A Fresh Take on CryptoCurrency
        </h2>
        <img src='april-ink-12_800.jpg'/>
      </div>
    );
  }
  
  export default Overview;
